import React, { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import PlainPage from "../../templates/plain-page";
import { firebaseFunc, storageRef } from "../../components/firebase/firestore";
import { formatDate } from "../../utils/utils";
import Avatar from "../../components/user/Avatar";
import { DonationPanels } from "../../components/DonationPanels";

const calcFields = (data) => ({
  // data returned from cloud function has _ in it's objects, like _seconds
  status: ["approved", "rejected"].includes(data.status)
    ? data.status
    : "awaiting",
  date: data.time ? new Date(data.time._seconds * 1000) : new Date(0),
  dateFormatted: data.time
    ? formatDate(new Date(data.time._seconds * 1000))
    : "",
  publish: data.publish ? "Yes" : "No",
});

const getData = (showMine) =>
  // email order just to be sure it exist
  firebaseFunc
    .httpsCallable(showMine ? "getUserDonations" : "getPublicDonations")()
    .then(({ data }) => {
      const res = {};

      const money = data[0];
      const time = data[1];
      const courses = data[2];
      const equipment = data[3];

      res.money = money.map((row) => ({
        ...row,
        ...calcFields(row),
      }));

      res.time = time.map((row) => ({
        ...row,
        ...calcFields(row),
      }));

      res.courses = courses.map((row) => ({
        ...row,
        ...calcFields(row),
      }));

      res.equipment = equipment.map((row) => ({
        ...row,
        ...calcFields(row),
      }));

      return res;
    })
    .catch(() => {
      alert(
        "You've reached a quota limit on usage of Hall of Fame page. Please wait 2 minutes and try again.",
      );

      return [];
    });

const getCommonColumns = (idPrefix, showMine) => [
  {
    Header: "Profile",
    columns: [
      {
        Header: "Logo",
        accessor: "logo_name",
        width: 90,
        disableFilters: true,
        Cell: ({ row }) => {
          const [src, setSrc] = useState();
          if (row.values.logo_name) {
            storageRef
              .child(`donate_${idPrefix}/${row.values.rowId}/logo`)
              .getDownloadURL()
              .then((url) => {
                setSrc(url);
              })
              .catch((error) => {
                alert(`Error loading file. ${error.message}`);
              });
          }
          return (
            <>
              <Avatar
                url={src}
                size="64"
                onClick={() => {
                  if (row.values.logo_name) {
                    window.open(src, "_blank");
                  }
                }}
              />
            </>
          );
        },
        show: true,
      },
      {
        Header: "First name",
        accessor: "first_name",
        show: false,
      },
      {
        Header: "Name",
        accessor: "surname",
        Cell: ({ row, data }) =>
          `${data[row.index].first_name} ${data[row.index].surname[0]}.`,
      },
      {
        Header: "Surname",
        accessor: "surname",
        show: false,
      },
      {
        Header: "Phone number",
        accessor: "phone",
        show: false,
      },
      {
        Header: "Email",
        accessor: "email",
        show: false,
      },
      {
        Header: "Institution/Organisations name",
        accessor: "organization",
        show: false,
      },
    ],
  },
  {
    Header: "Additional",
    columns: [
      {
        Header: "Personal message",
        accessor: "message",
        show: false,
      },
      {
        Header: "Publish donation",
        accessor: "publish",
        // Filter: SelectColumnFilter({ dispatch }),
        filter: "equals",
        show: showMine,
      },
      {
        Header: "Logo",
        accessor: "logo_name",
        disableFilters: true,
        Cell: ({ row }) => (
          <>
            {row.values.logo_name && (
              <a
                className="tabs-link nowrap"
                target="_blank"
                onClick={() => {
                  if (row.values.logo_name) {
                    storageRef
                      .child(`donate_courses/${row.values.rowId}/logo`)
                      .getDownloadURL()
                      .then((url) => {
                        // check for SSR, when there is no window
                        if (typeof window !== "undefined") {
                          window.open(url, "_blank");
                        }
                      })
                      .catch((error) => {
                        alert(`Error loading file. ${error.message}`);
                      });
                  }
                }}
              >
                Download logo
              </a>
            )}
          </>
        ),
        show: false,
      },
    ],
  },
  {
    Header: "Info",
    columns: [
      {
        Header: "User ID",
        accessor: "uid",
        show: false,
      },
      {
        Header: "Entry ID",
        accessor: "rowId",
        show: false,
      },
      {
        Header: "Submission date",
        accessor: "date",
        sortType: "datetime",
        show: false,
        Cell: ({ row, data }) => data[row.index].dateFormatted,
      },
      {
        Header: "Verification status",
        accessor: "status",
        // Filter: SelectColumnFilter({ dispatch }),
        filter: "includes",
        show: false,
      },
    ],
  },
];

const getMoneyColumns = (showMine) => [
  ...getCommonColumns("money", showMine),
  {
    Header: "Donation",
    columns: [
      {
        Header: "Payment amount",
        accessor: "donation_amount",
      },
    ],
  },
];

const getTimeColumns = (showMine) => [
  ...getCommonColumns("time", showMine),
  {
    Header: "Time & Skills",
    columns: [
      {
        Header: "Skills",
        accessor: "time-skills",
      },
      {
        Header: "Time available",
        accessor: "time-available",
      },
      {
        Header: "Available per",
        accessor: "time-period",
      },
      {
        Header: "Number of people",
        accessor: "time-people",
      },
      {
        Header: "Basis",
        accessor: "time-basis",
      },
      {
        Header: "From",
        accessor: "time-from",
      },
      {
        Header: "To",
        accessor: "time-to",
      },
    ],
  },
];

const getCoursesColumns = (showMine) => [
  ...getCommonColumns("courses", showMine),
  {
    Header: "Courses & Scholarships",
    columns: [
      {
        Header: "Description",
        accessor: "details",
      },
      {
        Header: "Syllabus",
        accessor: "file_name",
        Cell: ({ row }) => (
          <>
            {row.values.file_name && (
              <a
                className="tabs-link nowrap"
                target="_blank"
                onClick={() => {
                  if (row.values.file_name) {
                    storageRef
                      .child(
                        `donate_courses/${row.values.rowId}/${row.values.file_name}`,
                      )
                      .getDownloadURL()
                      .then((url) => {
                        window.open(url, "_blank");
                      })
                      .catch((error) => {
                        alert(`Error loading file. ${error.message}`);
                      });
                  }
                }}
              >
                Download file
              </a>
            )}
          </>
        ),
      },
    ],
  },
];

const getEquipmentColumns = (showMine) => [
  ...getCommonColumns("equipment", showMine),
  {
    Header: "Tools & Equipment",
    columns: [
      {
        Header: "Description",
        accessor: "details",
      },
      {
        Header: "Syllabus",
        accessor: "file_name",
        disableFilters: true,
        Cell: ({ row }) => (
          <>
            {row.values.file_name && (
              <a
                className="tabs-link nowrap"
                target="_blank"
                onClick={() => {
                  if (row.values.file_name) {
                    storageRef
                      .child(
                        `donate_equipment/${row.values.rowId}/${row.values.file_name}`,
                      )
                      .getDownloadURL()
                      .then((url) => {
                        window.open(url, "_blank");
                      })
                      .catch((error) => {
                        alert(`Error loading file. ${error.message}`);
                      });
                  }
                }}
              >
                Download file
              </a>
            )}
          </>
        ),
      },
    ],
  },
];

const Content = ({ currentUser, location }) => {
  const [moneyData, setMoneyData] = useState([]);
  const [timeData, setTimeData] = useState([]);
  const [coursesData, setCoursesData] = useState([]);
  const [equipmentData, setEquipmentData] = useState([]);
  const [, setDonationsTotal] = useState(0);
  const [showMine, setShowMine] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [loading, setLoading] = useState(false);

  const [, setMoneyColumns] = useState(getMoneyColumns(showMine));
  const [, setTimeColumns] = useState(getTimeColumns(showMine));
  const [, setCoursesColumns] = useState(getCoursesColumns(showMine));
  const [, setEquipmentColumns] = useState(getEquipmentColumns(showMine));

  const refreshData = (res) => {
    setMoneyData(res.money);
    setTimeData(res.time);
    setCoursesData(res.courses);
    setEquipmentData(res.equipment);
    setDonationsTotal(
      res.money.length +
        res.time.length +
        res.courses.length +
        res.equipment.length,
    );
  };

  useEffect(() => {
    setLoading(true);
    getData(showMine).then((res) => {
      refreshData(res);
      setLoading(false);
    });
    setMoneyColumns(getMoneyColumns(showMine));
    setTimeColumns(getTimeColumns(showMine));
    setCoursesColumns(getCoursesColumns(showMine));
    setEquipmentColumns(getEquipmentColumns(showMine));
  }, [currentUser, showMine]);

  useEffect(() => {
    if (typeof window !== "undefined" && location.hash !== "") {
      const num = Number(location.hash[1]);
      if (!Number.isNaN(num) && num >= 0 && num < 4) {
        setTabIndex(num);
      }
    }
  }, [location.hash]);

  return (
    <div className="column is-12">
      <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
        Hall of Fame
      </h2>
      <div className="columns is-desktop">
        <div className="column is-12">
          <div className="is-size-6 m-b-lg">
            {currentUser ? (
              <>
                {showMine ? (
                  <p>This is a list of your latest donations.</p>
                ) : (
                  <p>This is a list of the latest published donations.</p>
                )}
                <div className="field m-t-sm m-b-lg">
                  <input
                    id="switchExample"
                    type="checkbox"
                    name="switchExample"
                    className="switch is-rounded"
                    checked={showMine}
                    onChange={() => {
                      setShowMine(!showMine);
                    }}
                  />
                  <label htmlFor="switchExample">Show my donations only</label>
                </div>
              </>
            ) : (
              <p>This is a list of latest public, approved donations.</p>
            )}
          </div>
          <Tabs
            className="tabs is-centered is-boxed is-toggle is-fullwidth"
            selectedTabClassName="is-active"
            selectedIndex={tabIndex}
            onSelect={(index) => {
              setTabIndex(index);
            }}
            forceRenderTabPanel
          >
            <TabList className="m-b-lg">
              <Tab>
                <a>
                  <span className="icon is-small">
                    <i className="far fa-money-bill-alt" />
                  </span>
                  <span>Financial</span>
                </a>
              </Tab>
              <Tab>
                <a>
                  <span className="icon is-small">
                    <i className="fas fa-hourglass-half" />
                  </span>
                  <span>Time & Skills</span>
                </a>
              </Tab>
              <Tab>
                <a>
                  <span className="icon is-small">
                    <i className="fas fa-graduation-cap" />
                  </span>
                  <span>Courses & Scholarships</span>
                </a>
              </Tab>
              <Tab>
                <a>
                  <span className="icon is-small">
                    <i className="fas fa-drafting-compass" />
                  </span>
                  <span>Tools & Equipment</span>
                </a>
              </Tab>
            </TabList>
            <TabPanel style={{ display: tabIndex === 0 ? "block" : "none" }}>
              {!loading && <DonationPanels idPrefix="money" data={moneyData} />}
            </TabPanel>
            <TabPanel style={{ display: tabIndex === 1 ? "block" : "none" }}>
              {!loading && <DonationPanels idPrefix="time" data={timeData} />}
            </TabPanel>
            <TabPanel style={{ display: tabIndex === 2 ? "block" : "none" }}>
              {!loading && (
                <DonationPanels idPrefix="courses" data={coursesData} />
              )}
            </TabPanel>
            <TabPanel style={{ display: tabIndex === 3 ? "block" : "none" }}>
              {!loading && (
                <DonationPanels idPrefix="equipment" data={equipmentData} />
              )}
            </TabPanel>
          </Tabs>
          {loading && <div className="button is-loading-spinner">Loading</div>}
        </div>
      </div>
    </div>
  );
};

const UserList = ({ location }) => {
  return <PlainPage Content={Content} location={location} isFluid />;
};

export default UserList;
