import React, { useState } from "react";
import Avatar from "./user/Avatar";
import { storageRef } from "./firebase/firestore";
import dayjs from "dayjs";

const getBody = (idPrefix, donation) => {
  switch (idPrefix) {
    case "money":
      return (
        <>
          <span className="is-size-6 has-text-weight-bold">
            ${donation.donation_amount}
          </span>{" "}
          donation
        </>
      );
    case "time": {
      const now = dayjs();
      const dateFrom = dayjs(donation["time-from"], "DD-MM-YYYY");
      const dateTo = dayjs(donation["time-to"], "DD-MM-YYYY");
      let status = [
        "On-Going ",
        <i
          key={donation.rowId + "-time-half"}
          className="fas fa-hourglass-half"
        ></i>,
      ];

      if (now.isBefore(dateFrom)) {
        status = [
          "Not Yet Started ",
          <i
            key={donation.rowId + "-time-start"}
            className="fas fa-hourglass-start"
          ></i>,
        ];
      } else if (now.isAfter(dateTo)) {
        status = [
          "Elapsed ",
          <i
            key={donation.rowId + "-time-end"}
            className="fas fa-hourglass-end"
          ></i>,
        ];
      } else if (donation["time-from"] === undefined) {
        status = "unknown (no date provided)";
      }

      return (
        <>
          <b>{donation["time-available"]}</b> hours per{" "}
          <b>{donation["time-period"]}</b>
          <br />
          in{" "}
          <b>
            {Array.isArray(donation["time-skills"])
              ? donation["time-skills"].join(", ")
              : donation["time-skills"]}
          </b>
          <br />
          <span className="has-text-grey">status: </span>
          {status}
        </>
      );
    }
    case "courses":
      return (
        <>
          <span className="is-size-6 has-text-weight-bold">Course</span>{" "}
          description: <br />
          <i>{donation.details}</i>
        </>
      );
    case "equipment":
      return (
        <>
          <span className="is-size-6 has-text-weight-bold">Tools & Equipment</span>{" "}
          description: <br />
          <i>{donation.details}</i>
        </>
      );

    default:
      return <></>;
  }
};

const DonationPanel = ({ idPrefix, donation }) => {
  const [src, setSrc] = useState();

  if (donation.logo_name) {
    storageRef
      .child(`donate_${idPrefix}/${donation.rowId}/logo`)
      .getDownloadURL()
      .then(url => {
        setSrc(url);
      })
      .catch(error => {
        alert(`Error loading file. ${error.message}`);
      });
  }

  return (
    <div className="card m-b-md">
      {/* <header className="card-header">
      <p class="card-header-title">
        <span className="icon is-small m-r-sm m-l-md">
          <i className="far fa-money-bill-alt" />
        </span>
        <span>Financial</span>
      </p>
    </header> */}
      <div className="card-content p-t-md p-b-md p-l-md p-r-md">
        <div className="content">
          <div className="media m-b-smm">
            <div className="media-left m-r-smm">
              <Avatar
                url={src}
                size="48"
                className="m-l-none m-r-none"
                onClick={() => {
                  if (donation.logo_name) {
                    window.open(src, "_blank");
                  }
                }}
              />
            </div>
            <div className="media-content">
              <p className="title is-6 overflow-hidden has-text-primary">
                {donation.first_name} {donation.surname[0]}.
              </p>
              {/* <p className=" is-6 ">UK</p> */}
            </div>
          </div>
          <p
            style={{
              marginLeft: "3.8rem",
              marginBottom: "0rem",
              marginTop: "-2.5rem",
            }}
          >
            {getBody(idPrefix, donation)}
          </p>
          <span
            className="has-text-grey"
            style={{ position: "absolute", top: "0.7rem", right: "1.5rem" }}
          >
            {donation.dateFormatted}
          </span>
        </div>
      </div>
      {/* <footer class="card-footer">ff</footer> */}
    </div>
  );
};

const DonationPanels = ({ idPrefix, data }) => {
  return (
    <>
      {data.map(donation => (
        <DonationPanel
          idPrefix={idPrefix}
          donation={donation}
          key={donation.rowId}
        />
      ))}
    </>
  );
};

DonationPanels.propTypes = {};

export { DonationPanels };
